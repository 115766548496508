// ======================================================================
// Articles
// ======================================================================

#article {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }

  .page-top {
    overflow: hidden;
    @include minbreak {
      @include column(8,2);
    }
  }
}

.page-title {
  text-align: center;
  @include rem(margin, 0 0 48px 0);
}

.wall-images {
  img {
    width: 100%;
    height: auto;
    @include rem(max-width, 267px);
    display: block;
    margin: 0 auto;
    border: 4px solid #FFFFFF;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.50);
  }

  a {
    &:focus {
      img {
        border: 4px solid $secondary;
      }
    }
  }
}

.bullets {
  ul {
    list-style: none;

    li {
      @include rem(margin, 0 0 8px 0);

      &:before {
        content: '\2714';
        margin: 0 8px 0 0;
        color: $brand-b;
      }
    }
  }
}

figure {
  margin: 0;
  padding: 0;

  img {
    width: 100%;
    height: auto;
  }
}


#fun-day-section #middle {
  p {
    font-family: $customfont;
    text-align: center;
      @include type-modular(epsilon, 0px, 24px );
  }
}
