// ======================================================================
// Forms
// ======================================================================

.uniform__potty {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}

.uniform-errors {
  @include rem(padding, 18px);
  @include rem(margin, 0 0 18px 0);
  background-color: #c0392b;
  font-weight: $semibold;
  color: $bright;
 
  p {
    font-weight: $semibold;
    color: $bright;
    font-family: $customfont;
  }
}

.uniform-succesful {
  @include rem(padding, 22px);
  @include rem(margin, 0 0 18px 0);
  background-color: #27ae60;
  font-weight: $semibold;
  color: $bright;


  p {
    font-weight: $semibold;
    color: $bright;
    @include type-modular(delta);
    text-align: center;
    font-family: $customfont;
  }
}

p.form-error-hint {
  color: $negative;
  font-weight: $semibold;
  @include type-modular(zeta, 0px, 24px);
  font-family: $global-font-stack;
}
