// ======================================================================
// Typography
// ======================================================================
// ======================================================================
// Root
// ======================================================================

:root {
  @include responsive-type;
}
// ======================================================================
// Custom Fonts
// ======================================================================
@include fontface("Leckerli One", "LeckerliOne-Regular", 400, normal, eot woff ttf svg);
// ======================================================================
// HTML
// ======================================================================

@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

html {
  @include rem-baseline(100%);
  line-height: $global-line-height;
  color: $global-font-color;
  font-family: $global-font-stack;
  font-weight: $global-font-weight;

  @include font-smoothing(on);

  text-rendering: optimizeLegibility;
  @include baseline;


}
// ======================================================================
// Headings
// ======================================================================

h1 {
  @include type-modular(gamma);
  // @include type-free(43px, 24px, 24px, 54px, true);
}

h2 {
  @include type-modular(delta);
  // @include type-free(32px, 24px, 24px, 54px, true);
}

h3 {
  @include type-modular(epsilon, 0px, 0px );
  // @include type-free(24px, 24px, 24px, 54px, true);
}

h4,
h5,
h6 {
  @include type-modular(zeta);
  // @include type-free($global-base-font-size, 24px, 0px, 24px, true);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $global-heading-color;
  font-family: $global-heading-stack;
  font-weight: $global-heading-weight;
}
// ======================================================================
// Paragraphs
// ======================================================================

p {
  @include type-modular(zeta, 0px, 24px);
  // @include type-free($global-base-font-size, 0px, 24px, 24px, true);
}
// ======================================================================
// Lists
// ======================================================================

ol,
ul {
  @include type-modular(zeta);
  // @include type-free($global-base-font-size, 0px, 24px, 24px, true);
  ol {
    margin-bottom: 0;
    margin-top: 0;
  }

  ul {
    margin-bottom: 0;
    margin-top: 0;
  }
}

dl {
  @include type-modular(zeta);
  // @include type-free($global-base-font-size, 0px, 24px, 24px, true);
  dt {
    font-weight: $semibold;
  }

  dd {
    font-style: italic;
  }
}
// ======================================================================
// Tables
// ======================================================================

table {
  @include table($default-table);
}
// ======================================================================
// Quotes
// ======================================================================

blockquote {
  @include type-modular(zeta);
  // @include type-free($global-base-font-size, 0px, 24px, 24px, true);
  font-style: italic;
}

blockquote > p {
  padding: 0;
}
// ======================================================================
// Misc
// ======================================================================

a {
  color: $link;
  text-decoration: none;
  &:focus {
    outline: none;
  }
  &:hover {
    color: $link-hover;
    text-decoration: $link-hover-decoration;
  }
}


p a {
  font-family: $customfont;
  &:focus {
    border-bottom: 2px solid $secondary;
  }
}

hr {
  border: 0;
  border-top: $global-hr-style;
  display: block;
  height: 1px;
  padding: 0;
}

sub,
sup {
  position: relative;
  top: -0.4rem;
  vertical-align: baseline;
}

sub {
  top: 0.4rem;
}

b {
  line-height: 1;
}

small {
  line-height: 1;
}

strong {
  font-weight: $bold;
  line-height: 1;
  font-family: $customfont;
  color: $brand-c;
}

cite,
dfn,
em,
q {
  line-height: $global-line-height;
}

// code {
//   @include type-free(14px, 0px, 24px, 24px, true);
// }
//
// kbd {
//   margin-bottom: 1px;
//   padding: 0;
//   position: relative;
//   top: -1px;
// }
//
// pre {
//   @include type-free(14px, 0px, 24px, 24px, true);
//   background-color: $dark;
//   color: $bright;
//   font-weight: $bold;
//   @include rem(padding, 12px) @include rem(border-radius, 4px);
//   font-family: $terminal;
//   white-space: pre;
//   word-break: break-all;
//   word-wrap: break-word;
//   white-space: pre-wrap;
//   white-space: pre\9;
// }
