/* ------ venobox.css --------*/
.vbox-overlay *,
.vbox-overlay *:after,
.vbox-overlay *:before {
  -webkit-backface-visibility: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.vbox-overlay {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1040;
  -webkit-transform: translateZ(1000px);
  transform: translateZ(1000px);
  transform-style: preserve-3d;
}
/* ----- navigation ----- */
.vbox-title {
  width: 100%;
  height: 40px;
  float: left;
  text-align: center;
  line-height: 28px;
  font-size: 12px;
  padding: 6px 40px;
  overflow: hidden;
  position: fixed;
  display: none;
  left: 0;
  z-index: 1050;
}

.vbox-close {
  cursor: pointer;
  position: fixed;
  top: -1px;
  right: 0;
  width: 50px;
  height: 40px;
  padding: 6px;
  display: block;
  background-position: 10px center;
  overflow: hidden;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  z-index: 1050;
}

.vbox-num {
  cursor: pointer;
  position: fixed;
  left: 0;
  height: 40px;
  display: block;
  overflow: hidden;
  line-height: 28px;
  font-size: 12px;
  padding: 6px 10px;
  display: none;
  z-index: 1050;
}
/* ----- navigation ARROWS ----- */
.vbox-next,
.vbox-prev {
  position: fixed;
  top: 50%;
  margin-top: -15px;
  overflow: hidden;
  cursor: pointer;
  display: block;
  width: 45px;
  height: 45px;
  z-index: 1050;
}

.vbox-next span,
.vbox-prev span {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-top-color: #B6B6B6;
  border-right-color: #B6B6B6;
  text-indent: -100px;
  position: absolute;
  top: 8px;
  display: block;
}

.vbox-prev {
  left: 15px;
}

.vbox-next {
  right: 15px;
}

.vbox-prev span {
  left: 10px;
  -ms-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.vbox-next span {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 10px;
}
/* ------- inline window ------ */
.vbox-inline {
  width: 420px;
  height: 315px;
  height: 70vh;
  padding: 10px;
  background: #fff;
  margin: 0 auto;
  overflow: auto;
  text-align: left;
}
/* ------- Video & iFrames window ------ */
.venoframe {
  max-width: 100%;
  width: 100%;
  border: none;
  width: 100%;
  height: 260px;
  height: 70vh;
}

.venoframe.vbvid {
  height: 260px;
}
@media (min-width: 768px) {
  .vbox-inline,
  .venoframe {
    width: 70%;
    height: 360px;
    height: 70vh;
  }

  .venoframe.vbvid {
    width: 640px;
    height: 360px;
  }
}
@media (min-width: 992px) {
  .vbox-inline,
  .venoframe {
    max-width: 1200px;
    width: 50%;
    height: 540px;
    height: 70vh;
  }

  .venoframe.vbvid {
    width: 960px;
    height: 540px;
  }
}
/*
Please do NOT edit this part!
or at least read this note: http://i.imgur.com/7C0ws9e.gif
*/
.vbox-open {
  overflow: hidden;
}

.vbox-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  z-index: 20;
  max-height: 100%;
}

.vbox-content {
  text-align: center;
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px 10px;
}

.vbox-container img {
  max-width: 100%;
  height: auto;
}

.figlio {
  box-shadow: 0 0 12px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  max-width: 100%;
  text-align: initial;
}

img.figlio {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.vbox-content.swipe-left {
  margin-left: -200px !important;
}

.vbox-content.swipe-right {
  margin-left: 200px !important;
}

.animated {
  webkit-transition: margin 300ms ease-out;
  transition: margin 300ms ease-out;
}

.animate-in {
  opacity: 1;
}

.animate-out {
  opacity: 0;
}
/* ---------- preloader ----------
 * SPINKIT
 * http://tobiasahlin.com/spinkit/
-------------------------------- */
.sk-double-bounce {
  width: 40px;
  height: 40px;
  margin: 40px auto;
}

.sk-double-bounce {
  position: relative;
}

.sk-double-bounce .sk-child {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-doubleBounce 2s infinite ease-in-out;
  animation: sk-doubleBounce 2s infinite ease-in-out;
}

.sk-double-bounce .sk-double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@-webkit-keyframes sk-doubleBounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-doubleBounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
