// ======================================================================
// Nav List
// ======================================================================

%navlist {
  margin: 0;
  padding: 0;


  li {
    display: inline-block;
    @include rem(margin, 0 12px 0 0);

  }

  a {
    border-bottom: 2px solid transparent;
    @include rem(padding, 4px 0);

    &:focus {
      color: $brand-b;
      border-bottom: 2px solid $brand-b;
    }
  }

  .active {
    color: $brand-b;
    border-bottom: 2px solid $brand-b;
  }
}
