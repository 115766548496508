// ======================================================================
// Panels
// ======================================================================

.icon-info {
  cursor: pointer;
}

.info-panel {
  background-color: #eee;
  @include rem(padding, 24px);
  @include rem(margin, 24px 0 24px 0);
  display: none;
  @include rem(border-radius, 4px);
}
