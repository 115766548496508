// ======================================================================
// Common
// ======================================================================

html {
  @include fix-box-model;
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  border-top: $page-border;
  border-bottom: $page-border;
}

#middle {
  flex: 1 0 auto;
  position: relative;
  border-left: $page-border;
  border-right: $page-border;
  @include rem(padding, 48px 10px 48px 10px);
}



.inlinecontent {
  display: none;
}

.advert {
  text-align: center;

  a {
    display: inline-block;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    @include rem(max-width, 648px);
  }
}
