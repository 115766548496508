// ======================================================================
// Home
// ======================================================================

#slidebox {
  @include clearfix("mojo");
  @include container(1140px);
}

#slides {
  width: 100%;
  height: 100%;
  border: 8px solid #ffffff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  @include column(10, 1);
  @include minbreak($bp-small) {
    @include column(8, 2);
  }
  @include minbreak($bp-medium) {
    @include column(10, 1);
  }
}

.slick-track {
  > div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
  }
  height: 200px;
  @include minbreak($bp-xsmall) {
    height: 300px;

    #the-park-section & {
      height: 250px;
    }
  }
  @include minbreak($bp-small) {
    height: 350px;

    #the-park-section & {
      height: 200px;
    }
  }
  @include minbreak($bp-medium) {
    height: 400px;

    #the-park-section & {
      height: 300px;
    }
  }
  @include minbreak($bp-large) {
    height: 500px;

    #the-park-section & {
      height: 300px;
    }
  }
}

.slide-title {
  @include rem(padding, 8px);

  span {
    display: block;
    @include type-modular(epsilon, 0px, 0);
  }
  display: none;
  @include minbreak($bp-small) {
    display: inline-block;
  }
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fff;
  color: $brand-b;
  font-family: $customfont;
}

#introbox {
  @include clearfix("mojo");
  @include container(1140px);
}

#intro {
  p {
    @include type-free(22px, 36px, 24px, 22 * 1.5px, true);
    color: $dark;
    font-family: $customfont;
  }
  clear: both;
  @include column(10, 1);
  @include minbreak($bp-small) {
    @include column(8, 2);
  }
  @include minbreak($bp-medium) {
    @include column(8, 2);
  }
  @include minbreak(1200px) {
    @include column(6, 1);
  }
  @include minbreak(1700px) {
    @include column(8, 1);
  }
}

// .funday {
//   @include maxbreak($bp-medium) {
//     display: none;
//   }
//   position: relative;
//   width: 100%;
//   height: 100%;

//   a {
//     font-family: $customfont;
//     background-color: #e74c3c;
//     @include gradient-vertical(
//       lighten(#27ae60, 25%),
//       lighten(#27ae60, 5%),
//       0%,
//       90%
//     );
//     border: 2px solid #fff;
//     box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
//     display: block;
//     position: absolute;
//     z-index: 50000;
//     width: 185px;
//     height: 185px;
//     border-radius: 50%;
//     text-align: center;
//     padding-top: 56px;
//     @include type-free(18px, 0px, 0px, 22 * 1.5px, true);
//     color: #fff;
//     transition: transform 0.2s ease-in-out;
//     transform: rotate(-10deg);
//     margin-top: -30px;

//     &:hover {
//       transform: rotate(-6deg);

//       span {
//         color: #fff;
//       }
//       color: #fff;
//     }
//   }
// }
