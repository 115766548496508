// ======================================================================
// Site Header
// ======================================================================

#site-header {
  background-color: #fff;
  border-left: $page-border;
  border-right: $page-border;
  @include rem(padding, 0px 10px);
}

.header-bar {
  @include holygrail( 'site-logo', 'site-title', 'site-menu', 1140px, 2, 5, 5, $gutter-width, 0%, true, $center-container-max-width, 0px);
}

.site-logo,
.site-menu,
.site-title {
  @include rem(padding, 24px 0 0 0);
}

.site-title {
  span {
    color: $brand-b;
    font-family: $customfont;
    display: block;
    @include type-modular(delta, 0px, 0px);
    text-align: center;
    @include minbreak(1140px) {
       @include type-modular(delta, 35px, 0px);
      text-align: left;
    }
  }
}

.site-menu {
  @include rem(padding, 20px 0 20px 0);
  font-family: $customfont;


  ul {
    @extend %navlist;
    width: 100%;
    text-align: center;

    li {
      &:last-child {
        margin: 0 0 0 0;
      }
    }

    @include minbreak(1140px) {
         text-align: right;
    }

    @include clearfix(mojo);
  }



  @include minbreak(1140px) {
     @include rem(padding, 75px 0 0 0);
  }
}

.site-logo {
  @include rem(padding, 36px 0 0 0);

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-width: 140px;
  }
}
