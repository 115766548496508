// ======================================================================
// Social Links
// ======================================================================

.social {
  margin: 0 0 rem(12px) 0;
  padding: 0;
  list-style: none;
  width: 100%;
  text-align: left;
  @include maxbreak($bp-medium) {
    text-align: center;
  }

  li {
    display: inline-block;
    margin: 0 rem(8px);

    &:first-child {
      margin: 0 rem(8px) 0 0;
    }
    // &:last-child {
    //   margin: 0 0 0 rem(8px);
    // }
  }

  a {
    border-bottom: none;
    color: $gray;

    &:active,
    &:focus {
      outline: none;
    }
  }
}

.soc {
  text-indent: -99999px;
  display: block;
  width: rem(22px);
  height: rem(22px);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &:focus,
  &:hover {
    opacity: 1;
  }
}

.soc-facebook {
  @include svgbg($facebook, $link);

  &:focus,
  &:hover {
    @include svgbg($facebook, $link-hover);
  }
}
