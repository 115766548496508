// ======================================================================
// Tarrifs
// ======================================================================

.tarrif-block {
  clear: both;
  @include rem(margin, 48px 0px);
  @include column(12);
  @include minbreak {
     @include column(8,2);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
}

.inlineinner {
  @include rem(padding, 36px);
}

.price-table {
  @include rem(padding, 0px 0px 48px 0px);
  overflow: hidden;
}
