// ======================================================================
// Colors
// ======================================================================


$brand-a: #EBF6F0;
$brand-b: #2C979C;
$brand-c: #6da5a7;


// Monochrome - Bright
// @group Monochrome Colors
$bright: #eeeeee;
// Monochrome - Medium
// @group Monochrome Colors
$medium: #9F9F9F;
// Monochrome - Dark
// @group  Monochrome Colors
$dark: #585858;
/// Monochrome - Black
/// // @group  Monochrome Colors
$black: #000;

// Palette - Primary
// @group Palette Colors
$primary: $brand-a;
// Palette - Secondary
// @group Palette Colors
$secondary: $brand-b;
// Palette - Secondary
// @group Palette Colors
$tertiary: $brand-c;

// Feedback - Positive
// @group Palette Colors
$positive: #2ECC71;
// Feedback - Negative
// @group Feedback Colors
$negative: #971318;
// Feedback - Warning
// @group Feedback Colors
$warning: #f39c12;
// Feedback - Disabled
// @group Feedback Colors
$disabled: #adadad;

// Gradient - Start
// @group Gradient Colors
$gradient-start: $primary;
// Gradient - Middle
// @group Gradient Colors
$gradient-middle: darken($primary, 20%);
// Gradient - End
// @group Gradient Colors
$gradient-end: darken($primary, 40%);
