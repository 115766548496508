// ======================================================================
// Building Blocks
// ======================================================================

// ======================================================================
// Grids
// ======================================================================

// Full Width
// ----------------------------------------------------------------------
.block-1 {
    @include container(768px, true, 0%);
    clear: both;

    > .block-col {
        @include column(12);
        @include rem(margin-bottom, $column-bottom-spacing);
    }
}

// Half Split
// ----------------------------------------------------------------------
.block-2 {
    @include gridler("block-col", $column-bottom-spacing, 6, 6, 6, 4%, 0%, 540px, $gridler-me, $gridler-lg, true, 768px);

}

// Thirds
// ----------------------------------------------------------------------
.block-3 {
    @include gridler("block-col", $column-bottom-spacing, 6, 4, 4, 4%, 0%, 540px, $gridler-me, $gridler-lg, true, 768px);
}


// Quarters
// ----------------------------------------------------------------------
.block-4 {
    @include gridler("block-col", $column-bottom-spacing, 4, 4, 3, 4%, 0%, 540px, $gridler-me, $gridler-lg, true, 768px);
}


// ======================================================================
// Layouts
// ======================================================================

%left-sidebar {
  @include flank(
  "left",
  $flank-main-element,
  $flank-flank-element,
  $flank-main-column,
  $flank-flank-column,
  $flank-breakpoint,
  $flank-column-bottom-spacing,
  $gutter-width,
  $container-margin,
  $center-containers,
  $center-container-max-width );
}

%right-sidebar {
  @include flank(
  "right",
  $flank-main-element,
  $flank-flank-element,
  $flank-main-column,
  $flank-flank-column,
  $flank-breakpoint,
  $flank-column-bottom-spacing,
  $gutter-width,
  $container-margin,
  $center-containers,
  $center-container-max-width );
}



%holygrail {
  @include holygrail(
  $holygrail-left-element,
  $holygrail-middle-element,
  $holygrail-right-element,
  $holygrail-breakpoint,
  3,
  5,
  4,
  $gutter-width,
  0%,
  true,
  $center-container-max-width,
  0px
  );
}

%left-aside {
  article {
    @include column(3,2);
    @media screen and (max-width: $bp-medium) {
       @include column(10,1);
    }
  }
}

%right-aside {
  article {
    @include column(3,7);
    @media screen and (max-width: $bp-medium) {
       @include column(10,1);
    }
  }
}

%center-aside {
  article {
    @include column(6,3);
    @media screen and (max-width: $bp-medium) {
       @include column(10,1);
    }
  }
}
