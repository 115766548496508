// ======================================================================
// Forms
// ======================================================================

.form {
  @include form-complete;
  clear: both;

  label,
  p,
  span {
    font-family: $customfont;
    color: $brand-c;
  }

  textarea {
    @include rem(min-height, 150px)
  }

  input[type="text"].picker-input {
    background-image: url("/assets/images/cal.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 98% 50%;
    cursor: pointer;
  }
}

$radio-default: ( use-borders: false, default: darken($primary, 8%), hover: darken($primary, 20%), focus: darken($primary, 20%), checked: darken($primary, 50%), disabled: $disabled, font-family: $customfont, textlight: $bright, textdark: darken($primary, 60%), textweight: $semibold, font-size: $global-base-font-size, textalign: center, height: 40px, width: 50%, margin: 0px 0px 24px 0px, padding: 0 9px, rounded: 4px, display: inline-block, boxsizing: content-box, border: 1px solid #fff );
@mixin radios($map-name: $radio-default) {
   @include clearfix(mojo);

  input[type="radio"] {
    visibility: visible;
    position: absolute;
    opacity: 0;

    + label {
      display: inline-block;
      float: left;
      width: map-get($map-name, width);
      height: map-get($map-name, height);
      line-height: map-get($map-name, height);
      // Text Color
      @if lightness(map-get($map-name, default)) < 60% {
        color: map-get($map-name, textlight);
      }
      @else {
        color: map-get($map-name, textdark);
      }
      background-color: map-get($map-name, default);
      border-radius: map-get($map-name, rounded);
      border: map-get($map-name, border);
      text-align: center;
      margin: map-get($map-name, margin);
      cursor: pointer;
      padding: 0;
      position: relative;
      font-size: map-get($map-name, font-size);
      font-family: map-get($map-name, font-family);

      &:hover {
        background-color: map-get($map-name, hover);
        @if lightness(map-get($map-name, hover)) < 60% {
          color: map-get($map-name, textlight);
        }
        @else {
          color: map-get($map-name, textdark);
        }
      }
    }

    &:checked + label {
      background-color: map-get($map-name, checked);
      @if lightness(map-get($map-name, checked)) < 60% {
        color: map-get($map-name, textlight);
      }
      @else {
        color: map-get($map-name, textdark);
      }
    }

    &:focus + label {
      background-color: map-get($map-name, focus);
      border: 1px solid #fff;

      &:hover {
        background-color: map-get($map-name, hover);
        @if lightness(map-get($map-name, hover)) < 60% {
          color: map-get($map-name, textlight);
        }
        @else {
          color: map-get($map-name, textdark);
        }
      }
    }

    &:checked + label {
      background-color: map-get($map-name, checked);
      @if lightness(map-get($map-name, checked)) < 60% {
        color: map-get($map-name, textlight);
      }
      @else {
        color: map-get($map-name, textdark);
      }

      &:hover {
        background-color: map-get($map-name, hover);
        @if lightness(map-get($map-name, hover)) < 60% {
          color: map-get($map-name, textlight);
        }
        @else {
          color: map-get($map-name, textdark);
        }
      }
    }

    &:checked:focus + label {
      background-color: map-get($map-name, focus);
      @if lightness(map-get($map-name, focus)) < 60% {
        color: map-get($map-name, textlight);
      }
      @else {
        color: map-get($map-name, textdark);
      }

      &:hover {
        background-color: map-get($map-name, hover);
        @if lightness(map-get($map-name, hover)) < 60% {
          color: map-get($map-name, textlight);
        }
        @else {
          color: map-get($map-name, textdark);
        }
      }
    }
  }
}

.fancy-radios {
  @include radios;
}

.needhome,
.needpitch,
.pitchoptions {
  display: none;
}

.buttons {
  text-align: right;
}


.filled {
  display: none;
}