// ======================================================================
// Typography Setup
// ======================================================================

// Basic Setup
// ======================================================================

/// Enable Type Debug
$type-debug: false;

// Set Global Font size
$global-base-font-size: 16px;

// Set Global Line height
$global-line-height: 1.5;

// Set Global REM Fallback
$rem-fallback: false;

// Set Global PX units only
$rem-px-only: false;

// Set Global line-heights with no units
$unitless-lineheight: false;

// The ratio used to generate sizes and margins on heading tags
$type-ratio: $perfect-fourth;

// Toggle percenatage based font scaling based on screen size.
$responsive-text: true;

// Global Font Smoothing
$webkit-smoothing: antialiased;

// Modular Steps
// ======================================================================

// Modular Stepping Multipliers
$modular-step-alpha: 5;
$modular-step-beta: 4;
$modular-step-gamma: 3;
$modular-step-delta: 2;
$modular-step-epsilon: 1;
$modular-step-zeta: 0;
$modular-step-eta: -1;
$modular-step-theta: -2;
$modular-step-iota: -3;

// Weights
// ======================================================================

// Ultralight Font Weight
$ultralight: 100;

// Thin Font Weight
$thin: 200;

// Light Font Weight
$light: 300;

// Regular Font Weight
$regular: 400;

// Semibold Font Weight
$semibold: 500;

// Bold Font Weight
$bold: 600;

// Extra Bold Font Weight
$extrabold: 700;

// Heavy Font Weight
$heavy: 800;

// Ultra Heavry Font Weight
$ultraheavy: 900;

// Global font styles
// ======================================================================

// Global Font Stack
// @group Font Stacks
$global-font-stack: $gillsans;

// Global Font Weight
// @group Font Stacks
$global-font-weight: $regular;

// Global Font Color
// @group Font Stacks
$global-font-color: $dark;

// Global heading font styles
// ======================================================================

// Heading Font Stack
// @group Font Stacks
$global-heading-stack: $customfont;

// Heading Font Color
// @group Font Stacks
$global-heading-weight: $extrabold;

// Heading Font Weight
// @group Font Stacks
$global-heading-color: $brand-b;

// Custom Font Path
// @group Font Stacks
$custom-font-path:"/assets/fonts/";

// Links
// ======================================================================

// Link Color
$link: $brand-c;

// Link Hover Color
$link-hover: darken($brand-c, 15%);

// Link Hover Color
$link-hover-decoration: none;

// Horizontal Rules
// ======================================================================

// Horizontal Rule color and thickness
$global-hr-style: 1px solid $primary;

// ======================================================================
// Responsive type scaling (set $responsive-text true for this)
// ======================================================================

// Base responsive font size for screens under $bp-tiny
$rt-bp-base: 87.5%; // 14px

// Responsive font size for screens between $bp-tiny and $bp-xsmall
$rt-bp-tiny: 87.5%; // 14px

// Responsive font size for screens between $bp-xsmall and $bp-small
$rt-bp-xsmall: 87.5%; // 14px

// Responsive font size for screens between $bp-xmall and $bp-medium
$rt-bp-small: 87.5%; // 14px

// Responsive font size for screens between $bp-medium and $bp-large
$rt-bp-medium: 100%; // 16px

// Responsive font size for screens between $bp-large and $bp-xl
$rt-bp-large: 100%; // 20px

// Responsive font size for screens between $bp-xl and above.
$rt-bp-xl: 100%; // 22px
