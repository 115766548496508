// ======================================================================
// Facilities
// ======================================================================

.footnote {
  p {
    font-family: $customfont;
    text-align: center;
  }
}
