// ======================================================================
// Contact
// ======================================================================

#map {
  @include rem(min-height, 350px);
  border: 8px solid #FFFFFF;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.50);
  @include rem(margin, 48px 8px)
}

.details {
  font-family: $customfont;

  .icons {
    color: $brand-c;
    display: inlin-block;
    @include rem(margin, 0 0 12px 0);
  }

  a {
    &:focus {
      color: $brand-b;
      border-bottom: 2px solid $brand-b;
    }
  }
}

.contact-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    @include rem(margin, 0 0 8px 0);
  }
}

.form {
  .consent-box {
    @include rem(padding, 24px);
    @include rem(margin-bottom, 24px);

    #contact-section & {
      @include rem(margin-top, 24px);
    }

    h3 {
      @include type-modular(epsilon, 0px, 24px);
    }
    border: 1px solid $brand-b;
    background-color: $brand-a;

    a,
    label,
    p,
    strong {
      font-family: $global-font-stack;
    }

    .form-error-hint {
      margin-bottom: 0;
    }

    label {
      font-weight: 600;
    }
  }

  .fancy-checks {
    @include checkradio;

    [type='checkbox'].error + label::before {
      border: 2px solid $negative;
    }

    [type='checkbox'].error:checked + label::before {
      border: 2px solid $positive;
    }
  }
}

#contact-section {
  .details {
    text-align: center;
  }
}
