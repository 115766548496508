// ======================================================================
// Custom Iconfont
// ======================================================================

@include fontface("fontawesomecustom", "fontawesomecustom", normal, normal, eot woff woff2 ttf svg);

.icons {
  @include rem(font-size, 18px);
  @include rem(line-height, 24px);
}


[class*=" icon-"]:before,
[class^="icon-"]:before {
  font-family: "fontawesomecustom";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1rem;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: '\e800';
}
/* '' */
.icon-info:before {
  content: '\e801';
}
/* '' */
.icon-mail:before {
  content: '\f0e0';
}
/* '' */
.icon-fax:before {
  content: '\f1ac';
}
/* '' */
