// ======================================================================
// Footer
// ======================================================================

footer {
  border-left: $page-border;
  border-right: $page-border;
  @include rem(padding, 16px 0px);
  background-color: $brand-a;
  font-family: $customfont;
}

.footer-details {
  @include flank( "right", $flank-main-element, $flank-flank-element, 9, 3, $flank-breakpoint, 0, 1%, 0, false, $center-container-max-width );
  color: $brand-c;

  .icons {
    @include rem(margin, 0 18px 18px 0);
    color: $brand-c;
    display: inline-block;
  }

  a {
    border-bottom: 2px solid transparent;

    &:focus {
      color: $brand-b;
      border-bottom: 2px solid $brand-b;
    }
  }
  @include maxbreak {
    text-align: center;
  }
}

.footer-navbar {
  @include rem(padding, 16px 0px);
  overflow: hidden;

  ul {
    @extend %navlist;
  }
}

.hashandsalt,
.registered {
  p {
    @include type-modular(eta, 0px, 0px);
    color: $brand-c;
  }
}

.trustwave {
  width: 105px;
  margin: auto 0 auto auto;
  @include maxbreak {
    margin: 24px auto;
  }
}

.hashandsalt {
  text-align: right;
  @include maxbreak {
     @include rem(margin, 12px auto 0);
    text-align: center;
  }
}

.hs-heart {
  color: #f53580;
}
